/*
 * Modified by Leonel Menjivar on 22-04-2023:
 * - Refactored getSettings() function to improve readability and maintainability
 * - Added new functions to handle specific tasks related to obtaining and storing application settings
 * - Updated the DataSharingService to include new subjects for currentMoney, currentTax, appName, alert_new_order_sound, primary_color, secondary_color, isRetail, isRestaurant, and show_supplier, to enable sharing of this information between components
 * - Added isRetail, isRestaurant, and show_supplier localStorage items to store information about retail and restaurant settings, and supplier information
 */


import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./services/auth.service";
import {HttpService} from "./services/http.service";
import {Observable, Subscription} from "rxjs";
import {LocalStorageService} from "./services/local-storage.service";
import {FirestoreService} from "./services/firestore.service";
import {DataSharingService} from "./services/data-sharing.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Admin';
  private isRestaurant: boolean = false;
  private isRetail: boolean = false;
  private typeBusiness = {
    isRestaurant: false,
    isRetail: false,
  }
  valor: string;
  valorSubscription: Subscription;
  sub: Subscription;
  collectionName = 'Settings';
  collectionField = 'settings_lastupdateday';
  orderByDir: any = 'asc';
  private currentMoney: string = localStorage.getItem('currentMoney') || '';
  private tax: number = 0;

  constructor(
      private router: Router,
      private auth: AuthService,
      private service: HttpService,
      private variablesLocales: LocalStorageService,
      private firestore: FirestoreService,
      private dataSharing: DataSharingService,
  ) {
    this.sub = this.firestore.search(this.collectionName, {
      'orderByName': this.collectionField,
      'orderByDir': this.orderByDir,
    }).subscribe(res1 => {
      this.getSettings();
    })
    this.valorSubscription = this.variablesLocales.getValorObservable('typeBusiness').subscribe(
        (valor: string) => {
          this.valor = valor;
        }
    );
  }

  ngOnInit(): void {

  }


  getSettings() {
    this.getOneSettingsApp('General').subscribe(res => {
      this.updateTypeBusiness(res);
      this.storeSettingsInLocalStorage(res);
      this.emitValuesUsingSubjects(res);
    });
  }

  getOneSettingsApp(settingName: string): Observable<any> {
    return this.service.sendGET_OneSettingsApp(settingName);
  }

  updateTypeBusiness(settings: any) {
    this.typeBusiness.isRestaurant = settings.isRestaurant;
    this.typeBusiness.isRetail = settings.isRetail;
  }

  storeSettingsInLocalStorage(settings: any) {
    localStorage.setItem('typeBusiness', JSON.stringify(this.typeBusiness));
    localStorage.setItem('currentMoney', settings.currentMoney);
    localStorage.setItem('appName', settings.appName);
    localStorage.setItem('primary_color', settings?.ionic_color.ion_color_base);
    localStorage.setItem('secondary_color', settings?.ionic_color_second.ion_color_base);
    localStorage.setItem('current_tax', settings.transactions['tax'] || 0);
    localStorage.setItem('service_fee_percent', settings.transactions['service_fee_percent'] || 0);
    localStorage.setItem('service_fee_limit', settings.transactions['service_fee_limit'] || 0);
    localStorage.setItem('include_service_fee', settings.transactions['include_service_fee'] || false);
    localStorage.setItem('include_tax', settings.transactions['include_tax'] || false);
    localStorage.setItem('unique_tax', settings.transactions['unique_tax'] || false);
    localStorage.setItem('isRetail', settings.isRetail || false);
    localStorage.setItem('isRestaurant', settings.isRestaurant || false);
    localStorage.setItem('show_supplier', settings.show_supplier || false);
    localStorage.setItem('alert_new_order_sound', settings.alert_new_order_sound || false);
    localStorage.setItem('transactions', JSON.stringify(settings.transactions || {}));
  }

  emitValuesUsingSubjects(settings: any) {
    this.dataSharing.currentMoneySubject.next(settings.currentMoney);
    this.dataSharing.currentTaxSubject.next(settings.transactions['tax'] || 0);
    this.dataSharing.appName.next(settings.appName);
    this.dataSharing.alert_new_order_sound.next(settings?.alert_new_order_sound.toString());
    this.dataSharing.primary_color.next(settings?.ionic_color.ion_color_base);
    this.dataSharing.secondary_color.next(settings?.ionic_color_second.ion_color_base);
    this.dataSharing.isRestaurant.next(settings.isRestaurant || false);
    this.dataSharing.isRetail.next(settings.isRetail || false);
    this.dataSharing.show_supplier.next(settings.show_supplier || false);
    this.dataSharing.unique_tax.next(settings.unique_tax || false);
    this.dataSharing.transactions.next(settings.transactions || {});
  }


  ngOnDestroy() {
  }

}
