export const environment = {
  production: false,
  currency: '',

  
  // PARAMETROS DON POLLO
  firebase: {
    apiKey: "AIzaSyBxaVNGbOnzvn0eNXFw1VtBKMuuYdrCTEs",
    authDomain: "ianos-don-pollo-dev.firebaseapp.com",
    projectId: "ianos-don-pollo-dev",
    storageBucket: "ianos-don-pollo-dev.appspot.com",
    messagingSenderId: "510596753749",
    appId: "1:510596753749:web:e13d7ab2129167f7fa27dc",
    measurementId: "G-9CFG5G33D8"
  },
  googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
  urlBase: 'https://us-central1-ianos-don-pollo-dev.cloudfunctions.net',
  apikey: '76447957dff-3ea692ca85c38-4b5a-dab34cf5',
  urlBaseWompy: 'https://us-central1-ianos-platform-dev.cloudfunctions.net/Wompi',
  
};
