import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';

import {LayoutModule} from './views/layout/layout.module';
import {AuthGuard} from './core/guard/auth.guard';

import {AppComponent} from './app.component';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';

import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {AngularFireModule} from "@angular/fire";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireStorageModule} from "@angular/fire/storage";
import {AngularFireFunctionsModule} from "@angular/fire/functions";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {environment} from "../environments/environment";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
import {CurrencyPipe} from './shared/pipes/currency.pipe';
import {OrdersComponent} from './views/pages/orders/orders.component';
import {CategoriesComponent} from './views/pages/categories/categories.component';
import {GoBackBreadcrumbComponent} from './views/pages/go-back-breadcrumb/go-back-breadcrumb.component';
import {AgmCoreModule} from "@agm/core";
import {IonIntlTelInputModule} from "ion-intl-tel-input";

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    CurrencyPipe,
    OrdersComponent,
    CategoriesComponent,
    GoBackBreadcrumbComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey
    }),
    IonIntlTelInputModule,
  ],
  providers: [
    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
